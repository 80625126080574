<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <RewardDialog
          v-if="claimed && claimedItem"
          :callbackClose="exitRwd"
          :showDialog="true"
          :rewards="[
            {
              Item: {
                images: {
                  icon: JSON.parse(claimedItem.translatableImage)[$_getLocale()]
                    ? JSON.parse(claimedItem.translatableImage)[$_getLocale()]
                        .url
                    : JSON.parse(claimedItem.translatableImage)[
                        getSubjectLang(claimedItem)
                      ].url,
                },
              },
              quantity: 1,
            },
          ]"
        />

        <ComingSoonDialog
          v-if="comingSoonDialog"
          :callbackClose="() => (comingSoonDialog = false)"
          :showDialog="true"
          :timestampStart="comingSoonTimestampStart"
          :bookKey="comingSoonBookKey"
        />

        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <!-- EOC -->
          <div class="pe-2 ps-3 app-title text-h5">
            ABC
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="px-5">
            <div class="mb-5" v-if="booksNew.length > 0">
              <div class="text-h5">{{ $t("string.newArrivals") }}</div>
              <BookCard
                v-for="(item, i) in booksNew"
                class="my-2"
                :key="i + 'all'"
                :data="item"
                :selectedBook="selectedBook"
                :api="api"
                :claimBook="claimBook"
                :onComingSoonButtonClicked="onComingSoonButtonClicked"
              />
            </div>
            <div class="mb-5" v-if="booksComing.length > 0">
              <div class="text-h5">{{ $t("string.comingSoon") }}</div>
              <BookCard
                v-for="(item, i) in booksComing"
                class="my-2"
                :key="i + 'coming'"
                :data="item"
                :selectedBook="selectedBook"
                :api="api"
                :claimBook="claimBook"
                :onComingSoonButtonClicked="onComingSoonButtonClicked"
              />
            </div>

            <div class="mb-5" v-if="myBooks.length > 0">
              <div class="text-h5">{{ $t("string.myBook") }}</div>
              <BookCard
                v-for="(item, i) in myBooks"
                class="my-2"
                :key="i + 'own'"
                :data="item"
                :selectedBook="selectedBook"
                :api="api"
                :claimBook="claimBook"
                :onComingSoonButtonClicked="onComingSoonButtonClicked"
              />
            </div>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    RewardDialog: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/ABC/RewardBookDialog"
      ),
    BookCard: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/ABC/BookCard"
      ),
    ComingSoonDialog: () =>
      import(
        /* webpackChunkName: "component-tutorial-dialog" */ "@/components/ComingSoonDialog.vue"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    comingSoonDialog: false,
    comingSoonTimestampStart: null,
    comingSoonBookKey: null,
    selectedBook: null,
    claimed: false,
    booksNew: [],
    booksComing: [],
    otherBooks: [],
    myBooks: [],
    claimedItem: null,
    maxDisplayedComingSoonBook: 3,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  async created() {
    await this.fetchBooks();
  },
  mounted() {
    //
  },
  methods: {
    exitRwd() {
      this.claimed = false;
      this.read(this.claimedItem);
    },
    async fetchBooks() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/book/browse";

      this.api.params = {
        isDebug: this.settings.debug,
      };

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        resp.Book.forEach((item) => {
          item.progress = 0; // Default progress if no chapters exist
          if (item._count && item._count.Chapter > 0) {
            item.progress = item._count.PlayerChapter / item._count.Chapter;
          }
        });
        [this.myBooks, this.otherBooks] = resp.Book.reduce(
          (acc, obj) => {
            if (obj["PlayerBook"].length > 0) {
              acc[0].push(obj);
            } else {
              acc[1].push(obj);
            }
            return acc;
          },
          [[], []]
        );
        //BOC: quick temporary solution to split new and upcoming books
        [this.booksNew, this.booksComing] = this.otherBooks.reduce(
          (group, obj)=> {
            if (this.$moment(obj.timestampStart).diff(this.$moment()) > 0) {
              if(this.settings.debug || group[1] && group[1].length < this.maxDisplayedComingSoonBook) group[1].push(obj); //in debug mode can see all
            }
            else {
              group[0].push(obj);
            }
            return group;
          },
          [[], []]
        )
        //EOC
      };
      await this.$api.fetch(this.api);
    },
    claimBook(book) {
      this.selectedBook = book;
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/book/claim";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.claimedItem = book;
        this.claimed = true;
        this.api.isLoading = false;
        // this.fetchBooks();
      };
      this.api.params = {
        bookKey: book.key,
      };
      this.$api.fetch(this.api);
    },
    exit() {
      this.$router.push({
        name: "PageExploreLanding",
      });
    },
    read(book) {
      this.$router.push({
        name: "PageABCBookCover",
        params: { bookKey: book.key },
      });
    },
    getSubjectLang(item) {
      if (item.subject == "Mandarin") {
        return "zh";
      } else if (item.subject == "Malay") {
        return "ms";
      } else {
        return "en";
      }
    },
    onComingSoonButtonClicked(data) {
      this.comingSoonDialog = true;
      this.comingSoonTimestampStart = data.timestampStart;
      this.comingSoonBookKey = data.key
    },
  },
};
</script>

<style scoped>
.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}
</style>