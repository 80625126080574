var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body"},[(_vm.claimed && _vm.claimedItem)?_c('RewardDialog',{attrs:{"callbackClose":_vm.exitRwd,"showDialog":true,"rewards":[
          {
            Item: {
              images: {
                icon: JSON.parse(_vm.claimedItem.translatableImage)[_vm.$_getLocale()]
                  ? JSON.parse(_vm.claimedItem.translatableImage)[_vm.$_getLocale()]
                      .url
                  : JSON.parse(_vm.claimedItem.translatableImage)[
                      _vm.getSubjectLang(_vm.claimedItem)
                    ].url,
              },
            },
            quantity: 1,
          },
        ]}}):_vm._e(),(_vm.comingSoonDialog)?_c('ComingSoonDialog',{attrs:{"callbackClose":() => (_vm.comingSoonDialog = false),"showDialog":true,"timestampStart":_vm.comingSoonTimestampStart,"bookKey":_vm.comingSoonBookKey}}):_vm._e(),_c('v-container',{staticClass:"app-body"},[(_vm.api.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.api}}):_vm._e(),_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" ABC "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit}})],1),_c('div',{staticClass:"px-5"},[(_vm.booksNew.length > 0)?_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("string.newArrivals")))]),_vm._l((_vm.booksNew),function(item,i){return _c('BookCard',{key:i + 'all',staticClass:"my-2",attrs:{"data":item,"selectedBook":_vm.selectedBook,"api":_vm.api,"claimBook":_vm.claimBook,"onComingSoonButtonClicked":_vm.onComingSoonButtonClicked}})})],2):_vm._e(),(_vm.booksComing.length > 0)?_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("string.comingSoon")))]),_vm._l((_vm.booksComing),function(item,i){return _c('BookCard',{key:i + 'coming',staticClass:"my-2",attrs:{"data":item,"selectedBook":_vm.selectedBook,"api":_vm.api,"claimBook":_vm.claimBook,"onComingSoonButtonClicked":_vm.onComingSoonButtonClicked}})})],2):_vm._e(),(_vm.myBooks.length > 0)?_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("string.myBook")))]),_vm._l((_vm.myBooks),function(item,i){return _c('BookCard',{key:i + 'own',staticClass:"my-2",attrs:{"data":item,"selectedBook":_vm.selectedBook,"api":_vm.api,"claimBook":_vm.claimBook,"onComingSoonButtonClicked":_vm.onComingSoonButtonClicked}})})],2):_vm._e()])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }